import React, { useContext, useState } from 'react';
import { useAuth } from '@frontegg/react';
import countries from 'i18n-iso-countries';
import Swal from 'sweetalert2';
import { apiBaseUrl, fetchBackend } from '../helpers/fetch';
import AddButton from './AddButton';
import { ElementContext } from './ElementContext';
import RemoveButton from './RemoveButton';
import SelectListButton from './SelectListButton';
import ContactInfo from './ContactInfo';
import phone from 'phone';
import InfoModal from './modals/InfoModal';
import SelectListModal from './modals/SelectListModal';
import TemplateModal from './modals/TemplateModal';
import { useTranslation } from 'react-i18next';
import ScheduleWhatsAppMessages from './modals/ScheduleWhatsAppMessages';
import axios from 'axios';
import { Icon } from '@iconify/react';
import AttachFileCard from './AttachFileCard';
import { checkTenantSession } from '../helpers/validations';
import BannedPhoneModal from './modals/BannedPhoneModal';
import BannedTenantModal from './modals/BannedTenantModal';
import SessionClosedModal from './modals/SessionClosedModal';
import socket from '../helpers/Socket';
import { useEffect } from 'react';
import SlateTextEditorEmpty from './SlateTextEditorEmpty';
import SlateTextEditorWithTemplate from './SlateTextEditorWithTemplate';
import '../styles/sidebar.css'
import 'animate.css';


let QRCode = require('qrcode.react');

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));


// Uso del replace insensitive-case
String.prototype.replaceAll = function(strReplace, strWith) {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};
let filesName = []


const FormularioWhatsApp = () => {
    
    const { user } = useAuth();
    const { inputList, setInputList, infoToShow, authenticated, setIsAuthenticated, codigoQR, nombreEmisor, numeroEmisor, setScheduleModal, setScheduleMessage, message, setTemplateModal, setShowInfoModal, toggleSidebar, delayMsg, QRSolicitado, setQRSolicitado, setNombreEmisor, setNumeroEmisor, userPlan, setFrequencySelected, oscillationMsg, globalEnv, setBannedPhoneModal, setBannedTenantModal, setCodigoQR, setSessionClosedModal, esMultisesion, totalMsgs, setTotalMsgs, userInformation, messageWithTemplate, setMessageWithTemplate, whatsappFilesName, setWhatsappFilesName, archivos, setArchivos, archivosImagen, setArchivosImagen } = useContext(ElementContext);
    
    const { t, i18n } = useTranslation();
    const [buttonSelected, setButtonSelected] = useState('')
    const [sendingMsg, setSendingMsg] = useState(false)
    const [infoSelected, setInfoSelected] = useState('')
    const [messageMaxLengthReached, setMessageMaxLengthReached] = useState(false)
    const [rawFileNames, setRawFileNames] = useState([])
    const [deleteSessionRequested, setDeleteSessionRequested] = useState(false)
    const [cancelRequested, setCancelRequested] = useState(false)
    
    const limiteMsgsPorPlan = globalEnv.PLANS[userPlan].maxMessages;

    socket.off('connection_qr').on('connection_qr', ({qr, tenantid}) => {
        const tenant = user.email.replace('@', '_')
        
        if( !esMultisesion && (qr === 'canceled') ){
            window.location.replace('/')
            return
        } else if( !esMultisesion && (tenant === tenantid)){
            setCodigoQR(qr)
        }
    })

    useEffect(() => {
        setInputList([ { numero: userInformation.countryCode } ])
    }, [])
    
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list)
    }

    // const handleTextChange = (e) => {
    //     const { value } = e.target;
    //     if( value.length <= globalEnv.MSG_MAX_LENGTH ){
    //         setMessageMaxLengthReached(false)
    //         setMessage(value);
    //     } else {
    //         setMessageMaxLengthReached(true)
    //     }
    // }

    const handleRemoveInput = (index) => {
        if(inputList.length >= 2){
            const list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
        } else {
            setInputList([
                {numero: ''}
              ])
        }
    }

    const controlarEnvioWhatsapp = async(num) => {
        const nuevoTotal = await num + parseInt(totalMsgs)
        return nuevoTotal > limiteMsgsPorPlan
    }


    const handleSubmit = async(e) => {
        e.preventDefault();
        setSendingMsg(true);
        const tenant = user.email.replace('@', '_')
        
        const numbers = [];
        const arrayMessages = []
        const arrayEstados = []
        const parsedNumbers = []
        let data = {};
        let body;
        let estado;

        // if(message. && filesName.length === 0)
        
        if(infoToShow.length === 0 && inputList[0].numero === ''){
            Swal.fire(t('warning'), t('atLeastOneContact'), 'warning')
            setSendingMsg(false)
            return
        }

        // Control de cuerpo de mensaje, detección de fraude
        let esFraude = false
        let isInWhiteList = await globalEnv.WHITE_LIST.includes(user.email)
        const lowerCaseMessage = message.toLowerCase()
        const words = globalEnv.BANNED_TEXT
        for(let word of words){
            if(lowerCaseMessage.includes(word)){ esFraude = true }
        }
        
        if(esFraude && !isInWhiteList){
            const data = {
                tenant: user.email,
                platform: 'Whatsapp',
                message
            }
            await fetchBackend('sendEmailRequest', data, 'POST')
            setSendingMsg(false)
            return
        }

        // Chequear si el es Tenant baneado
        const isBannedTenant = await globalEnv.BANNED_TENANTS.includes(tenant)
        if( isBannedTenant ){
            setBannedTenantModal(true)
            setSendingMsg(false)
            return
        }

        // Controlar que sigue teniendo sesión abierta
        const tenantHasSession = await checkTenantSession(tenant)
        if(!tenantHasSession){
            await fetchBackend( `delete-session-folder/${tenant}`, {}, 'DELETE' )
            setSessionClosedModal(true)
            return
        }
        
        // Si hay archivos, enviarlos a la API
        if(archivos !== null || archivosImagen !== null){
            const formData = new FormData();

            if(archivos !== null){
                for( let i=0; i<archivos.length; i++ ){
                    formData.append("files", archivos[i])
                }
            }
            if(archivosImagen !== null){
                for( let i=0; i<archivosImagen.length; i++ ){
                    formData.append("files", archivosImagen[i])
                }
            }

            await axios.post(`${apiBaseUrl}/api/send-media`, formData)
                       .then(res => 'Archivos subidos')
                       .catch(error => console.log(error))
        }

        // Contactos sin agendar
        if( !(infoToShow.length > 0) ){
            // Filtro de inputs vacíos | Campo número
            inputList.map( num => {
                if(num.numero === ''){
                    console.log('Campo vacío encontrado')
                } else {
                    numbers.push(num.numero)
                }
            })
        }

        // Contactos agendados
        if(infoToShow.length > 0){
            // Filtro de inputs vacíos | Campo número
            infoToShow.map( contacto => {
                if(contacto.numero === ''){
                    console.log('Campo vacío encontrado')
                } else {
                    numbers.push(contacto.numero)
                }
            })
        }

        // Control de cantidad de SMS a punto de enviar
        const controlWhatsapp = await controlarEnvioWhatsapp(numbers.length)
        if(controlWhatsapp){
            setSendingMsg(false)
            Swal.fire(t('warning'), t('whatsappLimitExceded'), 'warning')
            return
        }
        
        for(let num in numbers){
            // Verifica si el mensaje se va a enviar o no, por ser número inexistente
            let number = numbers[num];
            if(!number.includes('+')){
                number = '+' + numbers[num]
            }
            estado = number.includes('503') ? true : phone(number).isValid;
            arrayEstados.push(estado);

            // Obtiene el país al cual pertenece el teléfono
            const parsedNum = await numbers[num].replace(/[^0-9]/g, '')
            parsedNumbers.push(parsedNum)
            
            let countryFullname;
            let countryShortname;
            let newMessages;

            if(infoToShow.length > 0){
                countryShortname = await infoToShow[num].pais || '';
                countryFullname = countries.getName(countryShortname, i18n.resolvedLanguage) || ''

                // const formatDate = new Date(infoToShow[num].customfields.fechavencimiento).toLocaleDateString()

                // Reemplazos de variables en el cuerpo del mensaje
                if(i18n.resolvedLanguage === 'en'){
                    newMessages = message.replaceAll('$name$', infoToShow[num].nombre)
                                            .replaceAll('$lastname$', infoToShow[num].apellido)
                                            .replaceAll('$position$', infoToShow[num].cargo)
                                            .replaceAll('$company$', infoToShow[num].empresa)
                                            .replaceAll('$country$', countryFullname)
                                            .replaceAll('$phone$', infoToShow[num].numero)
                                            .replaceAll('$observations$', infoToShow[num].observaciones)
                                            // .replaceAll('$billdue$', infoToShow[num].customfields.deuda)
                                            // .replaceAll('$expirationdate$', infoToShow[num].observaciones)
                } else {
                    newMessages = message.replaceAll('$nombre$', infoToShow[num].nombre)
                                            .replaceAll('$apellido$', infoToShow[num].apellido)
                                            .replaceAll('$cargo$', infoToShow[num].cargo)
                                            .replaceAll('$empresa$', infoToShow[num].empresa)
                                            .replaceAll('$pais$', countryFullname)
                                            .replaceAll('$numero$', infoToShow[num].numero)
                                            .replaceAll('$observaciones$', infoToShow[num].observaciones)
                                            // .replaceAll('$deuda$', infoToShow[num].customfields.deuda)
                                            // .replaceAll('$fechavencimiento$', formatDate)
                }
                
                arrayMessages.push(newMessages)
            }
        }

        data = {
            datosEmisor: {nombreEmisor, numeroEmisor},
            datosDestinatarios: infoToShow.length > 0 ? infoToShow : null,
            message,
            messages: arrayMessages, //Array con msgs reemplazados
            arrayEstados,
            numbers: parsedNumbers.length === 0 ? numbers : parsedNumbers, //Array con los numeros destinatarios
            usuario: user.email,
            tenantid: tenant,
            time: parseInt(delayMsg),
            oscillationMsg,
            canal: 'WhatsApp',
            filesName: whatsappFilesName
        }

        const resultado = await fetchBackend( 'send-whatsapp', data, 'POST' );
        body = await resultado.json();

        if( body.ok ){
            Swal.fire(t('success'), t('msgSuccess'), 'success');

            // Input files reset
            document.querySelector('#attach-imagefiles').value = '';
            document.querySelector('#attach-appfiles').value = '';
            filesName = []
            setWhatsappFilesName([])
            setArchivos(null)
            setArchivosImagen(null)
            setRawFileNames([])
            setSendingMsg(false)
        } else {
            Swal.fire('Oops', t('problem'), 'error')
            setSendingMsg(false)
        }
        
        setTotalMsgs(parseInt(totalMsgs) + numbers.length)
        setSendingMsg(false)
    }
  

    const handleLogout = async() => {
        setDeleteSessionRequested(true)
        const tenantid = user.email.replace('@', '_')
        
        const resultado = await fetchBackend( `eliminar-sesion/${tenantid}`, {}, 'DELETE' )
        const body = await resultado.json();
        if(body.ok){
            window.location.replace('/formulario-whatsapp')
        }
    }
    
    const openScheduleModal = (e) => {
        e.preventDefault();
        if(!message){
            return Swal.fire(t('warning'), t('beforeScheduleMsg'), 'warning')
        }

        setScheduleMessage(message)
        setFrequencySelected('seleccionar')
        setScheduleModal(true)
    }
    
    const openTemplateModal = (e) => {
        e.preventDefault();

        setMessageWithTemplate('')
        setButtonSelected(e.target.value)
        setTemplateModal(true)
    }

    const handleInfoModal = (e) => {
        e.preventDefault()
        setInfoSelected(e.target.id)
        setShowInfoModal(true)
    }

    const handleCancelQR = async() => {
        setCancelRequested(true)
        const tenantid = user.email.replace('@', '_')
        const removeSession = await fetchBackend(`eliminar-sesion/${tenantid}`, {}, 'DELETE')
        const removeResponse = await removeSession.json()

        if( removeResponse.ok ){
            window.location.replace('/')
        }
    }

    const handleFilesChange = async(e) => {
        setArchivos(e.target.files)
        const filesArray = await e.target.files
        for(let i=0; i<filesArray.length; i++){
            const nameFile = filesArray[i].name.split('.').shift().replace(/[^a-zA-Z0-9]/g, '')
            const ext = filesArray[i].name.split('.').pop()
            filesName.push(`${nameFile}.${ext}`)
        }
        
        setRawFileNames([...filesName])
        setWhatsappFilesName([...filesName])
        document.querySelector('#attachfilecard').click();
    }
    
    const handleImageFilesChange = async(e) => {
        setArchivosImagen(e.target.files)
        const filesArray = await e.target.files
        for(let i=0; i<filesArray.length; i++){
            const nameFile = filesArray[i].name.split('.').shift().replace(/[^a-zA-Z0-9]/g, '')
            const ext = filesArray[i].name.split('.').pop()
            filesName.push(`${nameFile}.${ext}`)
            
        }
        setRawFileNames([...filesName])
        setWhatsappFilesName([...filesName])
        document.querySelector('#attachfilecard').click();
    }
    
    const handleRemoveAttachFiles = () => {
        // Input files reset
        document.querySelector('#attach-imagefiles').value = '';
        document.querySelector('#attach-appfiles').value = '';
        filesName = []
        setWhatsappFilesName([])
        setArchivos(null)
        setArchivosImagen(null)
        setRawFileNames([])
    }

    const handleGetQR = async() => {
        setCancelRequested(true)
        setQRSolicitado(true)
        console.log('Solicitando QR')
        const tenant = user.email.replace('@', '_')
        const BANNED_PHONES = globalEnv.BANNED_PHONES
        const checkIfSessionExists = await fetchBackend(`check-session/${tenant}`)
        const checkResp = await checkIfSessionExists.json()
        
        setTimeout( () => {
            setCancelRequested(false)
        }, 5000)

        if(checkResp.sessionExists){
            const {pushname, wid} = await checkResp.info;
            const isBanned = BANNED_PHONES.includes(await wid.user)
            setBannedPhoneModal(isBanned)

            setNombreEmisor(pushname)
            setNumeroEmisor(wid.user)
            setQRSolicitado(false)
            setIsAuthenticated(true)
        } else {
            await fetchBackend(`qr-request/${tenant}`);
        }
    }


    return authenticated ? ( // authenticated
      <div className="home animate__animated animate__fadeIn">
          <div className="d-flex align-items-center justify-content-between">
              <div className={ toggleSidebar ? 'titles-margin-close' : 'titles-margin-open' }>
                  <div className="text">
                      {t('sendMsgsByWpTitle')}
                  </div>
                  <p className='subtitle mb-0'>
                      {t('sendMsgsByWpSubtitle')}
                  </p>
              </div>
              
              <div className='d-flex align-items-center'>
                <div className='d-flex flex-column me-5'>
                    <div className='normal-line d-flex align-items-center'>
                        <i className='bx bxs-user bx-icon-size m-1'></i>
                        <span className='user-font user-label'>{nombreEmisor}</span>
                    </div>
                    <div className='normal-line d-flex align-items-center'>
                        <i className='bx bx-phone bx-icon-size m-1'></i>
                        <span className='user-font user-label'>{numeroEmisor}</span>
                    </div>
                </div>
                
                <button
                    className='btn btn-sm btn-darkblue me-4'
                    onClick={ handleLogout }
                    disabled={ deleteSessionRequested }
                >
                    {
                        deleteSessionRequested ? (
                            <>
                              <span className='text-light'>{t('processing')}...</span>
                              <div className="spinner-border text-light loading-button ms-2" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                            </>
                        ) : t('disconnectWp')
                    }
                </button>
              </div>
          </div>

          <div className="container">
              <div className="row d-flex justify-content-between my-4">
                    <div className="col-6 btn-group btn-group-width">
                        <AddButton/>
                        <SelectListButton/>
                        <RemoveButton/>
                    </div>
                    
                  
                    <div className='col-6 d-flex justify-content-between align-items-center'>
                        <div>
                            <button
                                className='btn btn-sm btn-warning'
                                id='antiblockingNotice'
                                onClick={ handleInfoModal }
                            >
                                { t('sendingRequirements') }
                            </button>
                        </div>
                        <div>
                            <small style={{ fontSize: 13, fontWeight: 600 }} className='text-mediumblue mb-0 me-3'>
                                {t('importantInfo')}
                            </small>
                            <i
                                className='bx bx-info-circle info-icon animate__animated animate__heartBeat animate__infinite infinite'
                                id='importantInfo'
                                onClick={ handleInfoModal }
                            ></i>
                        </div>
                    </div>
              </div>

              
              <form id="whatsappForm" encType='multipart/form-data'>
                  <div className="row d-flex justify-content-between">
                      <div id="formDiv" className="col-6 mb-3">
                          <label className="form-label text-91">
                              {t('contacts')} - {t('enterPhoneNumber')}
                          </label>
                          {
                              infoToShow.length > 0 ? (
                                <ContactInfo/>
                              ) : (
                                inputList.map( (item, index) => {
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-1">
                                            <input
                                                type="text"
                                                className="form-control me-2"
                                                placeholder={t('enterPhoneNumber')}
                                                name="numero"
                                                value={item.numero}
                                                onChange={ e => handleInputChange(e, index) }
                                            />
                                            <i
                                                className='bx bx-trash icon text-danger me-4 pointer'
                                                onClick={ () => handleRemoveInput(index) }
                                            ></i>
                                        </div>
                                    )})
                              )
                          }
                      </div>
                      
                      <div className="col-6">
                          
                            <div className="d-flex align-items-center justify-content-between">
                                <label htmlFor="wa-text" className="form-label text-91">
                                    {t('message')}
                                </label>

                                {
                                    messageMaxLengthReached && (
                                        <small className='d-flex align-items-center text-lightblue mb-2'>
                                            <i className='bx bxs-hand x-size me-2'></i>
                                            <span>{ t('maxCharReached') }</span>
                                        </small>
                                    )
                                }
                            </div>

                            <div className='message-container'>
                                {
                                    messageWithTemplate ? <SlateTextEditorWithTemplate /> : <SlateTextEditorEmpty />
                                }
                            </div>
                            <>
                                <div className='attachfile-position'>
                                    <label htmlFor="attachfilecard" className='pointer attachicon-position'>
                                        <Icon icon="ion:attach-outline" className='attach-icon' />
                                    </label>
    
                                    <AttachFileCard />
                                </div>

                                <input
                                    className='d-none'
                                    type="file"
                                    name="imagefiles"
                                    id="attach-imagefiles"
                                    accept='image/*'
                                    onChange={ handleImageFilesChange }
                                    multiple
                                />
                                <input
                                    className='d-none'
                                    type="file"
                                    name="appfiles"
                                    id="attach-appfiles"
                                    accept='application/*'
                                    onChange={ handleFilesChange }
                                    multiple
                                />
                            </>


                            {
                                (rawFileNames.length > 0) && (
                                    <>
                                        <ul className='attach-ul'>
                                            <div className='d-flex justify-content-between mt-3'>
                                                <span>{t('attachedFiles')}:</span>

                                                <button
                                                    className='btn btn-91 btn-removeattach'
                                                    onClick={ handleRemoveAttachFiles }
                                                >
                                                    {t('removeFiles')}
                                                </button>
                                            </div>
                                            {
                                                rawFileNames.map( file => (
                                                    <li key={file} className='ms-3'>
                                                        <small className='text-mediumblue'>{file}</small>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </>
                                )
                            }

                            <div className='btn-group w-100'>
                                <button
                                    id="form-button"
                                    className="btn btn-sm btn-lightblue mt-3 w-25"
                                    type="submit"
                                    onClick={ (e) => handleSubmit(e) }
                                    disabled={ sendingMsg || (message.length < 5 && filesName.length < 1) }
                                >
                                    {
                                        sendingMsg ? (
                                            <div>
                                                <span className='text-light'>
                                                    {t('sending')}...
                                                </span>
                                                <div className="spinner-border text-light loading-button ms-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : t('send')
                                    }
                                </button>
                            
                            {
                                userPlan === 'trial' || userPlan === 'personal' ? null : (
                                        <button
                                            id="form-button"
                                            className="btn btn-sm btn-mediumblue mt-3 w-25"
                                            type="submit"
                                            onClick={ openScheduleModal }
                                            disabled={ infoToShow.length === 0 }
                                        >
                                            {t('scheduleMsg')}
                                        </button>
                                )
                            }
                            
                                <button
                                    id="form-button"
                                    className="btn btn-sm btn-lightblue mt-3 w-25"
                                    type="submit"
                                    onClick={ openTemplateModal }
                                    value="opentemplate"
                                >
                                    {t('openTemplate')}
                                </button>

                                <button
                                    id="form-button"
                                    className="btn btn-sm btn-darkblue mt-3 w-25"
                                    type="submit"
                                    onClick={ openTemplateModal }
                                    value="savetemplate"
                                    disabled={ !message }
                                >
                                    {t('saveTemplate')}
                                </button>
                            </div>
                            
                            <div className='d-flex justify-content-end align-items-center mt-2'>
                                <small style={{ fontSize: 13, fontWeight: 600 }} className='text-mediumblue mb-0 me-3'>
                                    {t('customMsgs')}
                                </small>
                                <i
                                    className='bx bx-message-alt-edit info-icon animate__animated animate__heartBeat animate__infinite infinite'
                                    id='customMessages'
                                    onClick={ handleInfoModal }
                                ></i>
                            </div>
                      </div>
                  </div>
              </form>
          </div>
  
          <InfoModal infoSelected={infoSelected}/>
          <SelectListModal/>
          <SessionClosedModal/>
          <ScheduleWhatsAppMessages/>
          <TemplateModal buttonSelected={buttonSelected} message={message} type={'standard'} />
          <BannedPhoneModal />
          <BannedTenantModal />
      </div>
    ) : (
        <div className="home animate__animated animate__fadeIn">
            <div className="row w-100">
                <div className="col-7">
                    <h3 className={
                        toggleSidebar
                        ? 'title ms-2 mt-4 mb-5 text-mediumblue'
                        : 'title ms-5 mt-4 mb-5 text-mediumblue'
                    }>
                        {t('linkToWhatsAppTitle')}:
                    </h3>
                    <ol className={
                        toggleSidebar
                        ? 'text-91 ol-size ms-2'
                        : 'text-91 ol-size ms-5'
                    }>
                        <li>{t('linkToWhatsAppPartOne')}</li>
                        <li>
                            {t('linkToWhatsAppPartTwo1')} <strong className='me-2'>{t('linkToWhatsAppPartTwo2')}</strong><i className='bx bx-dots-vertical-rounded icon-custom'></i> {t('linkToWhatsAppPartTwo3')} <strong>{t('linkToWhatsAppPartTwo4')}</strong>
                        </li>
                        <li>{t('linkToWhatsAppPartThree')}</li>
                        <li>
                            <strong>{t('linkToWhatsAppPartFour1')}</strong>. {t('linkToWhatsAppPartFour2')}.
                        </li>
                    </ol>
                </div>

                <div className="col-5 d-flex align-items-center justify-content-center">
                    <div className="text m-5">
                        {
                            codigoQR ? (
                                <div className="d-flex justify-content-center">
                                    <QRCode
                                        value={codigoQR}
                                        renderAs='svg'
                                        size={ 400 }
                                        className='p-4 bg-white animate__animated animate__fadeInDown'
                                    />
                                </div>
                            ) : (
                                QRSolicitado ? (
                                    <div className='d-flex flex-column justify-content-center align-items-center my-5'>
                                        <h5 className='me-4 mb-0 text-color'>
                                            {t('gettingSession')}...
                                        </h5>
                                        <div className="spinner-grow text-color mt-4" role="status"></div>
                                    </div>
                                ) : (
                                    <button
                                        className='btn btn-sm btn-mediumblue ms-5 mt-5'
                                        onClick={ handleGetQR }
                                    >
                                        {t('getSession')}
                                    </button>
                                )
                            )
                        }

                        {
                            QRSolicitado && (
                                <div className="d-flex justify-content-center mt-4">
                                    <button
                                        className='btn btn-sm btn-91'
                                        onClick={ handleCancelQR }
                                        disabled={ cancelRequested }
                                    >
                                        {
                                            cancelRequested ? t('processing') : t('cancel')
                                        }
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            <BannedPhoneModal />
        </div>
    )
  };
  
  export default FormularioWhatsApp;
  